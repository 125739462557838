/* /////////////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////////////// */
const winHeigt = window.innerHeight;
// const scroll = window.pageYOffset || document.documentElement.scrollTop;
let fadePos = document.getElementsByClassName("fade_pos");
let fadePoslength = fadePos.length;
let fadePosArr = [];
for ( let i = 0; i < fadePoslength; i++ ) {
  fadePosArr.push( fadePos[i].getBoundingClientRect().top );
}
// console.log( fadePosArr );


/* /////////////////////////////////////////////////////////////// */
// window.onload = function() {
//   for ( let j = 0; j < fadePoslength; j++ ) {
//     fadePos[j].classList.remove("textShow");
//   }
// }

window.addEventListener('scroll', function(){
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  // console.log( "scrollTop: " + scrollTop );

  // let hitline = ( winHeigt + scrollTop ) - 270;
  let hitline = ( winHeigt + scrollTop ) - 250;
  // console.log( "hitline: " + hitline );

  for ( let j = 0; j < fadePoslength; j++ ) {
    if ( hitline > fadePosArr[j] ) {
      fadePos[j].classList.add("textShow");
    }
  }

});
